import { EventType, Product, UppercaseProduct } from '@uc/web/shared/data-models';
import { StatementKeywordAndCategory } from '@uc/web/shared/data-access';

export interface StickyLeadBarEvent {
	id: number;
	date: string;
	product: UppercaseProduct;
	external_url: string;
	type: EventType;
	university: {
		id: number;
		name: string;
		order: number | null;
		privacy_policy_url: string | null;
	};
}

export interface BaseDataManager {
	link: string;
	title: string;
	uniId: number | undefined;
	product: Product;
	mobileDescription: string;
	desktopDescription: string;

	getData(data: StatementKeywordAndCategory | StickyLeadBarEvent): void;
}

export type StickyLeadBarColorScheme = 'BLUE' | 'GREEN' | 'ROSE';

export type StickyLeadBarType = 'REQUEST_INFO' | 'BOOK_EVENT' | 'VISIT_WEBSITE';

export enum StickyLeadBarColorEnum {
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	ROSE = 'ROSE',
}

export enum StickyLeadBarTypeEnum {
	REQUEST_INFO = 'REQUEST_INFO',
	BOOK_EVENT = 'BOOK_EVENT',
	VISIT_WEBSITE = 'VISIT_WEBSITE',
}

export enum FakeBtnTextEnum {
	BOOK_EVENT = 'Book Event',
	VISIT_WEBSITE = 'Visit Website',
	REQUEST_INFO = 'Request Info',
}

export enum FakeBtnStyleEnum {
	BOOK_EVENT = 'book-event-btn',
	VISIT_WEBSITE = 'blue-button',
	REQUEST_INFO = 'primary-green-btn',
}
