import { gql } from 'graphql-tag';

const GET_EVENTS = gql`
	query getEvents($limit: Int!) {
		events(limit: $limit) {
			data {
				id
				date
				product
				external_url
				type
				university {
					id
					name
					order
					privacy_policy_url
				}
			}
			recommended_events {
				id
				date
				product
				external_url
				type
				university {
					id
					name
					order
					privacy_policy_url
				}
			}
		}
	}
`;

export { GET_EVENTS };
