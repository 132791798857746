import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	distinctUntilChanged,
	withLatestFrom,
	switchMap,
	map,
	catchError,
	of,
} from 'rxjs';
import { Injectable } from '@angular/core';
import {
	fetchEvents,
	loadStickyLeadBarFailure,
	updateEvents,
} from './sticky-lead-bar.actions';
import { getStateEvents } from './sticky-lead-bar.selectors';
import { StickyLeadBarApiService } from '../sticky-lead-bar-api.service';

@Injectable()
export class StateStickyLeadBarEffects {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _stickyLeadBarApiSrv: StickyLeadBarApiService,
	) {}

	fetchEvents = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchEvents),
			distinctUntilChanged(),
			withLatestFrom(this._store.select(getStateEvents)),
			switchMap(() => this._stickyLeadBarApiSrv.getEvents()),
			map((payload) => {
				return updateEvents({
					events: payload,
				});
			}),
			catchError((error) => of(loadStickyLeadBarFailure(error))),
		),
	);
}
