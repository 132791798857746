import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ColorSchemeDirective } from '../../directives/color-scheme.directive';
import {
	FakeBtnStyleEnum,
	FakeBtnTextEnum,
	StickyLeadBarColorScheme,
	StickyLeadBarType,
	StickyLeadBarTypeEnum,
} from '../../models/sticky-lead-bar.models';
import {
	BookEvent,
	BookEventGTMService,
	CourseGTMService,
	RequestInfoGTMService,
	abbreviateProduct,
} from '@uc/web/core';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { EntitiesPipe } from '@uc/utilities';
import { ArrowRightSvgComponent } from '@uc/shared/svg';
import { BookEventBtnApiService, BookEventModalComponent } from '@web/shared/ui';
import { BookEventModalService } from 'libs/web/shared/ui/src/lib/modals/book-event-modal/book-event-modal.service';
import { ModalService } from '@uc/shared/ui';
import { CustomCookieService } from '@uc/shared/authentication';
import { Router } from '@angular/router';

@Component({
	selector: 'uc-content-sticky-lead-bar',
	standalone: true,
	templateUrl: './content-sticky-lead-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [EntitiesPipe, CommonModule, ColorSchemeDirective, ArrowRightSvgComponent],
})
export class ContentStickyLeadBarComponent implements OnInit, AfterViewChecked {
	window: Window & typeof globalThis;

	colorScheme = input.required<StickyLeadBarColorScheme>();
	leadType = input.required<StickyLeadBarType>();
	title = input.required<string>();
	description = input.required<string>();
	link = input.required<string>();
	componentName = input.required<string>();
	uniId = input.required<number>();
	uniName = input.required<string>();
	isUser = input.required<boolean>();
	product = input.required<Product>();
	privacyPolicyUrl = input<string | null>(null);
	ugSearchOrderPosition = input<number | null>(null);
	eventType = input<string>('');
	eventId = input<number | null>(null);
	date = input<string>('');

	userId = this._customCookieSrv.get('user_id');

	@ViewChild('fakeBtn') fakeBtn!: ElementRef<HTMLSpanElement>;

	protected isiOSChrome: boolean = true;
	protected buttonText = 'Book Event';

	constructor(
		@Inject(PLATFORM_ID) private _platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		private _router: Router,
		private _bookEventGTMSrv: BookEventGTMService,
		private _courseGTMSrv: CourseGTMService,
		private _requestInfoGTMSrv: RequestInfoGTMService,
		private _bookEventBtnApiSrv: BookEventBtnApiService,
		private _bookEventModalSrv: BookEventModalService,
		private _modalSrv: ModalService,
		private _customCookieSrv: CustomCookieService,
	) {
		this.window = this.document.defaultView as Window & typeof globalThis;
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.isiOSChrome = navigator.userAgent.indexOf('CriOS') >= 0;
		}
	}

	ngAfterViewChecked(): void {
		this._setFakeBtnTextAndStyle();
	}

	onClick(event: Event) {
		if (this.leadType() === StickyLeadBarTypeEnum.BOOK_EVENT) {
			this._bookEventClick(event);
		} else if (this.leadType() === StickyLeadBarTypeEnum.VISIT_WEBSITE) {
			this._visitWebsiteClick();
		} else if (this.leadType() === StickyLeadBarTypeEnum.REQUEST_INFO) {
			this._requestInfoClick();
		}
	}

	private _bookEventClick(event: Event) {
		event.preventDefault();

		const args: BookEvent = {
			uniId: this.uniId(),
			uniName: this.uniName(),
			isUser: this.isUser(),
			componentName: this.componentName(),
			eventType: this.eventType(),
			date: this.date(),
			ugSearchOrderPosition: this.ugSearchOrderPosition(),
		};

		if (this.userId) {
			this._bookEventModalSrv.externalLink.set(this.link());
			this._bookEventModalSrv.eventId.set(this.eventId());
			this._bookEventModalSrv.product.set(this.product());
			this._bookEventModalSrv.bookEventGTMArgs.set(args);
			this._bookEventModalSrv.privacyPolicyUrl.set(this.privacyPolicyUrl());

			this._modalSrv.setContent(BookEventModalComponent);
			this._modalSrv.show();
			return;
		}

		this.window.open(this.link());
		this._bookEventGTMSrv.bookEventClick(args);
		if (typeof this.eventId() === 'number') {
			this._bookEventBtnApiSrv.trackEventClicks(
				this.uniId(),
				this.eventId()!,
				this.product(),
			);
		}
	}

	private _visitWebsiteClick() {
		this._courseGTMSrv.keywordClick(
			this.componentName(),
			this.uniId(),
			this.uniName(),
			this.isUser(),
			this.product(),
		);
	}

	private _requestInfoClick() {
		this._requestInfoGTMSrv.requestInfoClick(
			this.componentName(),
			this.product() === ProductEnum.Undergraduate || !this.product()
				? this.ugSearchOrderPosition()
				: null,
		);

		this._router.navigate(['/request'], {
			queryParams: {
				uniId: this.uniId(),
				product: abbreviateProduct(this.product()),
			},
		});
	}

	private _setFakeBtnTextAndStyle() {
		this.fakeBtn.nativeElement.innerText = FakeBtnTextEnum[this.leadType()];
		this.fakeBtn.nativeElement.classList.add(FakeBtnStyleEnum[this.leadType()]);
	}
}
