<section
	scrollIntoView
	[scrollThreshold]="scrollThreshold"
	class="z-10 w-full bg-white sticky-lead-bar"
>
	<uc-content-sticky-lead-bar
		[title]="title"
		[description]="isMobile ? mobileDescription : desktopDescription"
		[colorScheme]="colorScheme"
		[link]="link"
		[leadType]="leadType"
		[componentName]="componentName"
		[uniId]="uniId"
		[isUser]="userAuthenticated"
		[product]="product"
		[eventType]="eventType"
		[eventId]="eventId"
		[date]="date"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
		[uniName]="uniName"
		[privacyPolicyUrl]="privacyPolicyUrl"
	/>
</section>
