<div class="flex justify-end gap-3">
	<uc-prospectus-btn
		customStyles="mb-0"
		[params]="uniId"
		[componentName]="componentName"
		[link]="prospectusExternalUrl ?? ''"
		[product]="product"
		[privacyPolicyUrl]="privacyPolicyUrl"
		[isFeaturedPostgraduate]="isFeaturedPostgraduate"
		[isFeaturedUndergraduate]="isFeaturedUndergraduate"
		[showModal]="true"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
	>
	</uc-prospectus-btn>
	<uc-request-btn
		[params]="uniId"
		[componentName]="componentName"
		[courseName]="courseName"
		[product]="product ?? productEnum.Undergraduate"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
	>
	</uc-request-btn>

	@if (externalUrl) {
		<uc-visit-website-btn
			[uniId]="uniId"
			[componentName]="componentName"
			[product]="product ?? productEnum.Undergraduate"
			[externalUrl]="externalUrl"
			[source]="source"
			[sourceType]="sourceType"
			[ugSearchOrderPosition]="ugSearchOrderPosition"
			[uniName]="uniName"
		/>
	}
	@if (externalEventsUrl) {
		<uc-book-event-btn
			[uniId]="uniId"
			[componentName]="componentName"
			[product]="product ?? productEnum.Undergraduate"
			[externalUrl]="externalEventsUrl"
			[ugSearchOrderPosition]="ugSearchOrderPosition"
			[privacyPolicyUrl]="privacyPolicyUrl"
			[uniName]="uniName"
		/>
	}
</div>
