<a
	[attr.href]="link()"
	target="_blank"
	rel="noopener sponsored"
	(click)="onClick($event)"
	[colorScheme]="colorScheme()"
	[classType]="'background'"
	class="py-4 flex items-center w-full min-h-[80px] border-t lg:min-h-32"
	[ngClass]="{ 'pb-7 min-h-[92px]': isiOSChrome }"
>
	<div class="container flex gap-3 lg:gap-20">
		<div class="w-full">
			<h3
				[colorScheme]="colorScheme()"
				classType="text"
				class="hidden text-xl font-bold leading-8 lg:block"
			>
				{{ (title() | ucEntities) || 'Want to learn more about a university?' }}
			</h3>
			<div class="flex items-center gap-3 lg:gap-20">
				<p
					[colorScheme]="colorScheme()"
					[classType]="'text'"
					class="w-full leading-6 lg:text-gray-600"
					[innerHTML]="
						(description() | ucEntities) ||
						'Get your questions answered by sending them an enquiry now.'
					"
				></p>
				<uc-arrow-right-svg
					customStyles="self-center w-auto lg:hidden"
					[colorScheme]="colorScheme()"
					[classType]="'text'"
				/>
			</div>
		</div>

		<div class="self-center hidden w-auto lg:block">
			<span
				#fakeBtn
				data-test-id="fake-btn"
				class="inline-flex items-center justify-center whitespace-nowrap sm:w-auto"
			>
				{{ buttonText }}
			</span>
		</div>
	</div>
</a>
